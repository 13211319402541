<template>
  <section class="team-section">
    <vue-headful title="Our Team | Goodyear Ventures" url="https://www.goodyearventures.com/team" description="A team that puts entrepreneurs first. Learn more about the leaders behind Goodyear Ventures."></vue-headful>
    <div class="page-background">
      <div class="team-header-wrapper">
        <h1 class="team-page-header1">Meet Our Team</h1>
      </div>
    </div>
    <b-container fluid v-bind:class="[modalIsOpen ? 'modal-wrapper-open' : 'modal-wrapper-closed']">
      <div class="modal-background" v-on:click="toggleModal()"></div>
      <div v-bind:class="[modalIsOpen ? 'modal-inner-open' : 'modal-inner-closed']" class="modal-inner">
        <div v-on:click="toggleModal()" class="closeBtn">
          <b-icon class="close-icon" icon="x" aria-hidden="true" font-scale="2"></b-icon>
        </div>
        <b-row class="modalContent-wrapper noMargin">
          <b-col class="bioImg-wapper" xs=12 sm=12 md=6 lg=4 xl=4>
            <div v-if="erinSpring == true" class="bioImg bioImg-ErinSpring"></div>
            <div v-if="abhijitGanguly == true" class="bioImg bioImg-AbhijitGanguly"></div>
            <div v-if="chittiRaju == true" class="bioImg bioImg-ChittiRaju"></div>
            <div v-if="chrisVarley == true" class="bioImg bioImg-ChrisVarley"></div>
            <div v-if="patrickCulhane == true" class="bioImg bioImg-PatrickCulhane"></div>
          </b-col>
          <b-col xs=12 sm=12 md=6 lg=8 xl=8>
            <div class="bioHeader">
              {{ bioName }}
            </div>
            <div class="bioSubheader">
              {{ bioTitle }}
            </div>
            <div class="gy-border bio-hr"></div>
            <div class="bioText">{{ bio }}</div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container fluid class="main-section">
      <b-row class="team-desc">
        <b-col sm="12">
          <h2 class="subheader gy-border">
            Goodyear Ventures <strong>Team</strong>
          </h2>
        </b-col>
        <b-col class="team-paragraph-wrapper" cols="12">
          <p id="team" class="team-paragraph">
            Goodyear believes in a future powered by new technologies, with a vision of sustainable, 
            safe, efficient, accessible and fun new mobility experiences. The Goodyear Ventures team 
            is helping to develop this vision by building a network of partners and investments. 
            Leveraging Goodyear’s global resources and technical expertise, we are dedicated to 
            supporting the startups that will drive the mobility revolution forward. Connect with 
            our team to learn more.
          </p>
        </b-col>
      </b-row>
      <b-row class="cards-wrapper d-flex justify-content-center">
        <b-col cols="12" sm="12" md="12" lg="4">
          <div class="card">
            <img class="card-img-top" src="../../assets/images/contacts/erin-spring.png" alt="Erin Spring headshot" width="260px" height="260px">
            <div class="card-body">
              <h4 class="card-title">Erin Spring</h4>
              <p class="card-text">Senior Director, Material Science</p>
              <div v-on:click="toggleModal(1)" class="modal-toggle">Read Bio ></div><br/>
              <a class="btn gy-primary" href="http://www.linkedin.com/in/erin-spring-0737315" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4">
          <div class="card">
            <img class="card-img-top" src="../../assets/images/contacts/abhijit-ganguly.jpg" alt="Abhijit Ganguly headshot" width="260px" height="260px">
            <div class="card-body">
              <h4 class="card-title">Abhijit Ganguly</h4>
              <p class="card-text">Managing Director, Goodyear Ventures</p>
              <div v-on:click="toggleModal(2)" class="modal-toggle">Read Bio ></div><br/>
              <a class="btn gy-primary" href="https://www.linkedin.com/in/abhijit-ganguly-6a823a12/" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center mb-5">
        <b-col cols="12" sm="12" md="12" lg="4">
          <div class="card">
            <img class="card-img-top" src="../../assets/images/contacts/chitti-raju.png" alt="Chitti Raju headshot" width="260px" height="260px">
            <div class="card-body">
              <h4 class="card-title">Chitti Raju</h4>
              <p class="card-text">Senior Corporate Venture Capital Associate</p>
              <div v-on:click="toggleModal(3)" class="modal-toggle">Read Bio ></div><br/>
              <a class="btn gy-primary" href="https://www.linkedin.com/in/chitti-raju/" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4">
          <div class="card">
            <img class="card-img-top" src="../../assets/images/contacts/chris-varley.jpg" alt="Chris Varley headshot" width="260px" height="260px">
            <div class="card-body">
              <h4 class="card-title">Chris Varley</h4>
              <p class="card-text">Principal, Goodyear Ventures</p>
              <div v-on:click="toggleModal(4)" class="modal-toggle">Read Bio ></div><br/>
              <a class="btn gy-primary" href="http://www.linkedin.com/in/cvarley" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="4">
          <div class="card">
            <img class="card-img-top" src="../../assets/images/contacts/patrick-culhane.png" alt="Patrick Culhane headshot" width="260px" height="260px">
            <div class="card-body">
              <h4 class="card-title">Patrick Culhane</h4>
              <p class="card-text">Principal, Goodyear Ventures</p>
              <div v-on:click="toggleModal(5)" class="modal-toggle">Read Bio ></div><br/>
              <a class="btn gy-primary" href="https://www.linkedin.com/in/patrick-culhane-584b1764/" target="_blank" variant="primary"><b-icon-linkedin></b-icon-linkedin>  LinkedIn</a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <img class="wingfoot" src="../../assets/images/goodyear-wingfoot.svg" alt="Goodyear Wingfoot icon"/>
    </section>
</template>

<script>
window.dataLayer = window.dataLayer || []

export default {
  name: 'Team',
  data: () => ({
      logo: require('@/assets/images/contacts/abhijit-ganguly.jpg'),
      modalIsOpen:false,
      bioName:'',
      bioTitle:'',
      bio:'',
      bioImg:'',
      bioImgAltTxt:'',
      erinSpring:false,
      abhijitGanguly:false,
      chittiRaju:false,
      chrisVarley:false,
      patrickCulhan:false,
  }),

  methods : {
    toggleModal: function(modalNum) {
      if(this.modalIsOpen) {
        this.modalIsOpen = false;

        this.erinSpring=false;
        this.abhijitGanguly=false;
        this.chittiRaju=false;
        this.chrisVarley=false;
      } else if(!this.modalIsOpen) {
        if(modalNum == 1) {
          this.bioName = 'Erin Spring';
          this.bioTitle = 'Senior Director, Material Science';
          this.bio = 'Based in Akron, OH, Erin Spring is Senior Director, Material Science of The Goodyear Tire & Rubber Company. In her prior role as Senior Director, New Ventures, Erin led the company’s New Venture team and was a founding member of Goodyear Ventures, AndGo by Goodyear and Goodyear SightLine and led the company’s global network of innovation labs. Erin has worked in a variety of roles at Goodyear since 2004. She began her career at Goodyear as a Materials Development Engineer, utilizing machine learning techniques to predict performance of tire materials.  She then led the Materials Testing organization and implemented lean concepts to drive efficiencies and deliver testing results on-time. Erin was also a key leader in building Goodyear’s New Business Innovation capability from the ground up. Erin has an MBA from the University of Akron and a bachelor’s in Science and Engineering in Chemical Engineering from The University of Michigan. She lives with her husband and two sons in Medina, OH.';
          // this.bioImg = '../../assets/images/contacts/erin-spring.png';
          this.bioImgAltTxt = 'Erin Spring headshot';
          this.erinSpring=true;
        } else if(modalNum == 2) {
          this.bioName = 'Abhijit Ganguly';
          this.bioTitle = 'Managing Director, Goodyear Ventures';
          this.bio = 'Based in Akron, OH, Abhijit Ganguly is the managing director of Goodyear Ventures, responsible for leading the venture fund. In his past roles, Abhijit led Goodyear’s Corporate Incubator focusing on IOT-enabled solutions businesses. Before joining Goodyear, Ganguly was responsible for driving growth initiatives in West Africa and Canada for a manufacturing company. Abhijit holds a bachelor’s degree in mechanical engineering from Jadavpur University in India and an MBA from the Tuck School of Business at Dartmouth in Hanover, NH, USA. He has co-authored two articles on Business Model Innovation & is the winner of the 2015 Maurice Holland Award for his contributions in the field of Business Model Innovation. Outside of work, Abhijit enjoys playing his acoustic guitar, cooking and golfing.';
          // this.bioImg = '../../assets/images/contacts/abhijit-ganguly.jpg';
          this.bioImgAltTxt = 'Abhijit Ganguly headshot';
          this.abhijitGanguly=true;
        } else if(modalNum == 3) {
          this.bioName = 'Chitti Raju';
          this.bioTitle = 'Senior Corporate Venture Capital Associate';
          this.bio = 'Based in the Washington D.C. metropolitan area, Chitti Raju serves as a Senior Corporate Venture Capital Associate with Goodyear Ventures, responsible for managing strategic projects with portfolio companies in addition to providing investment research and due diligence support. In his past role at Goodyear, Chitti worked on pricing and growth analytics while managing Mid-Atlantic operations at AndGo by Goodyear, an AI fleet management software. Before joining Goodyear, Chitti supervised east coast e-scooter markets for Ford Mobility, while creating forecasts, infrastructure investment recommendations and management strategies. Prior to Ford, Chitti worked at Virginia Tech, leading the Alternative Transportation department. Chitti holds two bachelor’s degrees in Biochemistry and Psychology from Virginia Tech in Blacksburg, Virginia. Outside of work, he enjoys cycling and restoring classic watercraft.';
          // this.bioImg = '../../assets/images/contacts/chitti-raju.png';
          this.bioImgAltTxt = 'Chitti Raju headshot';
          this.chittiRaju=true;
        } else if(modalNum == 4) {
          this.bioName = 'Chris Varley';
          this.bioTitle = 'Principal, Goodyear Ventures';
          this.bio = 'Based in the Greater Boston area, Chris Varley serves as a Principal with Goodyear Ventures, focusing on mobility and sustainability-related investments that enhance Goodyear' + "'" + 's products and mobility services. Prior to Goodyear, Chris led new business and new product creation efforts for several major corporations including AT&T, CompuServe, Prentice-Hall and The Walt Disney Company. Previously, as New Market Development Vice President for AT&T Labs, Chris led a venture development and investment group that commercialized emerging technologies outside of traditional AT&T markets. As a two-term Generation Foundation Fellow for Sustainable Development and former Vice President of the Northeast Ohio Technology Coalition (Nortech), Chris played a critical role in the early development of the clean energy movement in Northeast Ohio. In addition to his operational experience, Chris has written more than 50 film, television and children' + "'" + 's book projects, and served as a key advisor on startup funding and strategic partnering for New Media Partners, a digital media and technology consultancy based in Glasgow, Scotland.';
          this.bioImg = '../../assets/images/contacts/chris-varley.jpg';
          this.bioImgAltTxt = 'Chris Varley headshot';
          this.chrisVarley=true;
        } else if(modalNum == 5) {
          this.bioName = 'Patrick Culhane';
          this.bioTitle = 'Principal, Goodyear Ventures';
          this.bio = 'Based in the greater San Francisco Bay Area, Patrick Culhane serves as a Principal with Goodyear Ventures, focusing on mobility investments that enhance Goodyear' + "'" + 's work with cutting edge startups. In his past roles at Goodyear, Patrick was an Entrepreneur in Residence developing relevant incubation opportunities for investment in Goodyear' + "'" + 's corporate incubator. He also managed a National Operations team at AndGo by Goodyear, an AI fleet management software. Prior to joining Goodyear, Patrick worked at UberEats, holding multiple Regional Sales Operations roles overseeing SMB sales acquisition strategies in the US,Canada, and across APAC. Prior to UberEats, Patrick worked for Caviar, leading sales and expansion in the Bay Area. Patrick holds a bachelor’s degree in Psychology from the University of California, Santa Barbara. Outside of work, he enjoys playing soccer and exploring National Parks.';
          this.bioImg = '../../assets/images/contacts/patrick-culhane.png';
          this.bioImgAltTxt = 'Patrick Culhane headshot';
          this.patrickCulhane=true;
        }
        this.modalIsOpen = true;
        window.dataLayer.push({ 'event': 'clickedReadBio', 'clickDetails': 'Read Bio: ' + this.bioName })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-background {
  /*background-position: 10% 50%;*/
  background-size: cover;
  background-repeat: no-repeat;
  /*position: fixed;*/
  position: absolute;
  top: 0;
  bottom: 0;
  /*left: 0;*/
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('../../assets/images/background/team-page-background-md.jpg');
  background-position: center;
}
.team-header-wrapper {
  margin-top: 50vh;
  text-align: center;
  display: flex;
  justify-content: center;
}
.team-page-header1 {
  z-index: 100;
  color: white;
  font-size: 56px;
  font-family: "Barlow Light", Arial, sans-serif !important;

  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: black 4px solid;
  border-image-source: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(252, 238, 33, 1), rgba(241, 90, 38, 1));
  border-image-source: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(252, 238, 33, 1), rgba(241, 90, 38, 1));
  border-image-slice: 1;
}
.wingfoot {
  height: 65px;
  position: absolute;
  left: 48%;
  top: calc(100vh - 38px);
}
.team-section {
  margin-bottom: 100px;
}
.main-section {
  margin-top: 100vh;
}
.team-desc {
  margin-bottom: 100px;
}
.team-paragraph-wrapper {
  max-width: 750px;
  margin: auto;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}
.team-paragraph {
  line-height: 28px;
  text-align: center;
}
.subheader {
  font-size: 32px;
  text-transform: capitalize;
  text-align: center;
  width: 355px;
  margin: auto;
  padding-bottom: 10px;
}
.card {
  display: flex;
  max-width: 300px;
  height: auto;
  border: none;
  margin: 0 auto;
  margin-bottom: 80px;
  box-shadow: 5px 10px 18px rgb(0,0,0, .2);
  -webkit-box-shadow: 5px 10px 18px rgb(0,0,0, .2);
}
.card-text {
  height: 75px;
}
.card img.card-img-top {
  width: 260px;
  height: 260px;
  margin-left: 1rem;
  border-radius: 0px;
  margin: 20px;
}
.card .card-body {
  padding: 1rem;
  text-align: center;
 /* margin: 0 auto;*/
}
.card .card-title {
  font-weight: bold;
}
.modal-toggle {
  font-family: "Barlow Bold", Arial, sans-serif;
  font-weight: 700;
  font-size: 12px;
  float: right;
  background-color: rgb(241, 90, 38);
  color: white;
  padding: 5px;
  width: 110px;
  border-radius: 30px;
  margin-bottom: 50px;
  transition: 0.2s
}
.btn.gy-primary {
  color: #000;
  text-transform: none;
  min-width: auto;
  width: 100%;
  justify-content:baseline;
}
br {
  display: none;
}

.modal-wrapper-open, .modal-wrapper-closed {
  /* display: none; */
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;

  animation-duration: 0.25s;
}

@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slidout {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes open {
  0% {display:none;}
  1% {display:block;}
  100% {display:block;}
}

/* @keyframes close {
  0% {display:block;}
  1% {display:none;}
  100% {display: none;}
} */

.modal-wrapper-open {
  /* display: block;
  opacity: 1; */
  animation-name: open;
}

.modal-wrapper-closed {
  display: none;
  /* animation-name: close;
  animation-duration: 2s;
  animation-iteration-count: 1; */
}

.modal-background {
  display: block;
  z-index: -1;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.closeBtn {
  z-index: 50;
  color: white;
  width: 2em;
  margin-left: auto;
  margin-right: 20px;
  transition: 0.2s;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transform: translateY(20px);
}

.closeBtn:hover {
  cursor: pointer;
  opacity: 0.5;
}

.close-icon {
  font-size: 1rem;
}

.modal-inner {
  margin: auto;
  background-color: white;
  overflow: scroll;
}

.modal-inner-open {
  animation-name: slidein;
  animation-duration: 0.3s;
}

.modal-inner-closed {
  animation-name: slideout;
  animation-duration: 0.3s;
}

.modalContent-wrapper {
  /* margin-top: 30px; */
  padding: 30px;
}

.bioImg {
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
  max-width: 100%;
  max-height: 100%;
}

.bioImg-ErinSpring {
  background-image: url('../../assets/images/contacts/erin-spring.png');
}

.bioImg-AbhijitGanguly {
  background-image: url('../../assets/images/contacts/abhijit-ganguly.jpg');
}


.bioImg-ChittiRaju {
  background-image: url('../../assets/images/contacts/chitti-raju.png');
}

.bioImg-ChrisVarley {
  background-image: url('../../assets/images/contacts/chris-varley.jpg');
}

.bioImg-PatrickCulhane {
  background-image: url('../../assets/images/contacts/patrick-culhane.png');
}


.bioImg-wapper {
  width: 100%;
  height: 100%;
}

.bioHeader {
  font-size: 24px;
  font-weight: 600;
}

.bioSubheader {
  margin-bottom: 30px;
  padding-bottom: 5px;
}

.bio-hr {
  margin-bottom: 30px;
}

.bioText {
  font-size: 18px;
}

.noMargin {
  margin-left: 0;
  margin-right: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modal-inner {
    width: 100vw;
    height: 100vh;
    margin-top: 0;
  }
  .bioSubheader {
    margin: auto;
    margin-bottom: 30px;
  }
  .bioImg-wapper {
    margin-bottom: 50px;
  }
  .bioImg {
    margin: auto;
  }
  .modal-background {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .modal-inner {
    width: 100vw;
    height: 100vh;
    margin-top: 0;
  }
  .bioImg-wapper {
    margin-bottom: 50px;
  }
  .bioImg {
    margin: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card img.card-img-top {
    max-width: 100%;
  }
  .modal-toggle:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  .modal-inner {
    margin: auto;
    margin-top: 7.5%;
    height: 80%;
    width: 90%;
  }
  .card-body {
    text-align: left;
  }
  .bioSubheader {
    margin: 0;
    margin-bottom: 30px;
  }
  .main-section {
    max-width: 1200px;
  }
  .modal-background {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  br {
    display: inline;
  }
  .modal-inner {
    margin: auto;
    margin-top: 7.5%;
    height: 70%;
    width: 70%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
