<template>
  <section id="portfolio" class="portfolio-section">
      <b-container>
        <b-row>
          <b-col>
            <h2 class="subheader">Our Portfolio</h2>
          </b-col>
        </b-row>
        <b-row>
          <div class="arrow-wrapper">
            <button class="left-arrow-button" v-on:click="prevPage()" :disabled="firstDisable" style="border: none;">
              <img class="left-arrow" src="../../assets/images/arrow.png" alt="arrow" />
            </button>
          </div>
          <b-row class="cards-scroll-wrapper">
            <div class="cards-wrapper">
              <div class="card-wrapper" v-for="item in currentItems" v-bind:key="item.id">
                <a class="gy-cardlink" :href="item.cardLink" v-on:click="trackClick($event)" target="_blank">
                <div class="card">
                  <span v-if="item.active" class="active"></span>
                  <span v-if="item.exited" class="exited">IPO</span>
                  <span v-if="item.acquired" class="acquired">ACQUIRED</span>
                  <img class="card-img-top" :src="require(`../../assets/images/portfolio/${item.cardImg}`)" :alt="item.alt" />
                  <div class="card-body">
                    <div class="card-footer text-right"><a class="gy-link">Learn More ></a></div>
                  </div>
                </div>
                </a>
              </div>
            </div>
          </b-row>
          <div class="arrow-wrapper-right">
            <button class="right-arrow-button" v-on:click="nextPage()" :disabled="lastDisable" style="border: none;">
              <img class="right-arrow" src="../../assets/images/arrow.png" alt="arrow" />
            </button>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-col sm="12" class="d-flex justify-content-xs-center justify-content-md-end portfolio-button-wrapper">
            <a class="btn gy-primary" href="/portfolio" variant="primary">All Companies ⟶</a>
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
window.dataLayer = window.dataLayer || []
export default {
  name: 'Portfolio',
  data () {
    return {
      portfolioCompanies: [
        {id: 0, cardLink: 'https://ampup.io/', active: true, exited: false, acquired: false, cardImg: 'ampup-logo-color.png', alt: 'AmpUp logo'},
        {id: 1, cardLink: 'https://www.autofleet.io/', active: true, exited: false, acquired: false, cardImg: 'autofleet-logo-color.png', alt: 'Autofleet logo'},
        {id: 2, cardLink: 'https://www.corractions.com/', active: true, exited: false, acquired: false, cardImg: 'corractions-logo.png', alt: 'CorrActions logo'},
        {id: 3, cardLink: 'https://www.envoythere.com/', active: true, exited: false, acquired: true, cardImg: 'envoy-logo.png', alt: 'Envoy logo'},
        {id: 4, cardLink: 'https://formant.io/', active: true, exited: false, acquired: false, cardImg: 'formant-logo.png', alt: 'Formant logo'},
        {id: 5, cardLink: 'https://gatik.ai/', active: true, exited: false, acquired: false, cardImg: 'gatik-logo-color.png', alt: 'Gatik logo'},
        {id: 6, cardLink: 'https://www.helm.ai/', active: true, exited: false, acquired: false, cardImg: 'helm-logo.png', alt: 'Helm logo'},
        {id: 7, cardLink: 'https://nova.xyz/', active: true, exited: false, acquired: false, cardImg: 'nova-helium-logo-color.png', alt: 'Nova Labs and Helium logo'},
        {id: 8, cardLink: 'https://ottometric.com/', active: true, exited: false, acquired: false, cardImg: 'ottometric-logo.png', alt: 'Ottometric logo'},
        {id: 9, cardLink: 'http://www.pretred.com', active: true, exited: false, acquired: false, cardImg: 'pretred-logo.png', alt: 'Pretred logo'},
        {id: 10, cardLink: 'https://www.recurrentauto.com/', active: true, exited: false, acquired: false, cardImg: 'recurrent-logo-color.png', alt: 'Recurrent logo'},
        {id: 11, cardLink: 'https://gorevel.com/', active: true, exited: false, acquired: false, cardImg: 'revel-logo-color.png', alt: 'Revel logo'},
        {id: 12, cardLink: 'https://www.getspiffy.com/', active: true, exited: false, acquired: false, cardImg: 'spiffy-logo-color.png', alt: 'Spiffy logo'},
        {id: 13, cardLink: 'https://www.starship.xyz/business/', active: true, exited: false, acquired: false, cardImg: 'starship-logo.png', alt: 'Starship Technologies logo'},
        {id: 14, cardLink: 'https://tactilemobility.com/', active: true, exited: false, acquired: false, cardImg: 'tactile-mobility-logo.png', alt: 'Tactile Mobility logo'},
        {id: 15, cardLink: 'https://zypp.app/', active: true, exited: false, acquired: false, cardImg: 'zypp-logo.png', alt: 'Zypp logo'}
      ],
      currentPageCount: 1,
      currPage: 1,
      companiesPerPage: 4,
      firstDisable: true,
      lastDisable: false
    }
  },
  methods: {
    trackClick (e) {
      let clickedUrl = e.currentTarget.href;
      console.log("tracking click for : " + clickedUrl)
      if (clickedUrl) {
        window.dataLayer.push({ 'event': 'clickedCustom', 'clickDetails': 'Home/Portfolio: ' + clickedUrl })
      } else {
        console.log("no click url exists")
      }
      
    },
    nextPage: function () {
      this.currPage = this.currPage + 1;
    },
    prevPage: function () {
      this.currPage = this.currPage - 1;
    }
  },
  computed: {
    currPageCount: function() {
      return Math.ceil(this.portfolioCompanies.length / this.companiesPerPage);
    },
    currentPageRange: function() {
      let first = (this.currPage - 1) * this.companiesPerPage + 1;
      let last = this.currPage === this.currPageCount ? this.portfolioCompanies.length : first + this.companiesPerPage - 1;
      return `${first} - ${last} of ${this.portfolioCompanies.length}`
    },
    currentItems: function() {
      // Pagination
      let startIndex = (this.currPage - 1) * this.companiesPerPage;
      let endIndex = startIndex + this.companiesPerPage;

      return this.portfolioCompanies.slice(startIndex, endIndex)
    }
  },
  watch: {
    companiesPerPage: function () {
      this.currPage = 1;
    },
    currentItems: function () {
      // Pagination
      let startIndex = (this.currPage - 1) * this.companiesPerPage;
      let endIndex = startIndex + this.companiesPerPage;

      if(startIndex == 0) {
        this.firstDisable = true;
      } else if(startIndex>0) {
        this.firstDisable = false;
      }

      if(endIndex == this.portfolioCompanies.length || endIndex > this.portfolioCompanies.length) {
        this.lastDisable = true;
      } else if(endIndex != this.portfolioCompanies.length || endIndex < this.portfolioCompanies.length) {
        this.lastDisable = false;
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subheader {
  margin-bottom: 80px;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.btn.gy-primary {
  color: #000;
  text-transform: none;
}
.gy-cardlink {
  display: block;
}
.gy-link {
  color: #F15A26;
}
.arrow-wrapper, .arrow-wrapper-right {
  width: 10%;
  margin-top: 55px;
}
.arrow-wrapper-right {
  float: right !important;
}
.left-arrow, .right-arrow {
  width: 45px;
  height: 45px;
  transition: 0.2s;
  border: none;
}
.left-arrow {
  transform: rotate(180deg);
  float: left;
}
.right-arrow {
  float: right;
}
.left-arrow-button:disabled, .right-arrow-button:disabled {
  opacity: 0.5;
}
.left-arrow-button:hover:disabled, .right-arrow-button:hover:disabled {
  cursor: not-allowed;
}
.left-arrow-button:focus, .right-arrow-button:focus {
  border: none;
  outline: none;
  background: radial-gradient(#F15A26 0%, #F15A26 55%, transparent 70%);
  width: 55px;
  height: 55px;
  border-radius: 55px;
}
.left-arrow-button {
  width: 100%;
  float: left;
  background-color: transparent;
}
.right-arrow-button {
  width: 100%;
  float: right;
  background-color: transparent;
}
.cards-scroll-wrapper {
  float: left;
  width: 80%;
  height: fit-content;
  margin-bottom: 50px;
}
.cards-wrapper {
  display: flex;
  justify-content: space-between;
  width: fit-content;
}
.card-wrapper {
  float: left;
  width: 20%;
}
.card-wrapper:last-child {
  margin-right: 0;
}
.card {
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0px;
  box-shadow: none;
  color: #000;
}
.active {
  height: 22px;
  display: block;
}
.exited {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000;
  background-color: #F9ED25;
  display: block;
  margin-left: 0px;
  margin-top: 0px;
  width: 40px;
  height: 22px;
  padding: 1px;
}
.acquired {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000;
  background-color: #F9ED25;
  display: block;
  margin-left: 0px;
  margin-top: 0px;
  width: 90px;
  height: 22px;
  padding: 1px;
}
.gy-cardlink:hover, .card:hover {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  
}
.gy-cardlink:hover .card {
  border: 1px solid rgba(0,0,0, .10);
  -webkit-box-shadow: 5px 10px 18px rgb(0 0 0 / 20%);
  box-shadow: 5px 10px 18px rgb(0 0 0 / 20%);
}
.card:hover .gy-link {
  text-decoration: underline;
}
.card img {
  width: 85%;
  margin: 0 auto;
  margin-top: 5px;
}
.card-body {
  opacity: 0;
  padding: 1rem;
}
.card:hover .card-body {
  opacity: 1;
}
.card-footer {
  background-color: transparent;
  border: none;
  padding: 0.75rem 0.5rem 0px;
}
.card-footer.long {
  padding-top:15px;
}
.portfolio-button-wrapper {
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 50px;
  z-index: 100;
}

/* Extra small devices (phones, between 281px and 600px) */
@media only screen and (min-width: 281px) and (max-width: 600px) {
  .card-wrapper {
    display: block;
    width: 100%;
    float: none;
  }
  .cards-wrapper {
    display: block;
    width: fit-content;
  }
  .cards-scroll-wrapper {
    float: left;
    width: 50%;
    margin-bottom: 50px;
  }
  .btn.gy-primary {
    padding: .375rem .5rem;
    min-width: 100%;
  }
  .arrow-wrapper, .arrow-wrapper-right {
    width: 25%;
    margin-top: 55px;
  }
  .arrow-wrapper-right {
    margin-left: 30px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-wrapper {
    float: right;
    width: 42%;
  }
  .card-wrapper:nth-child(odd) {
    float: left;
  }
  .cards-wrapper {
    display: block;
    width: fit-content;
  }
  .cards-scroll-wrapper {
    float: left;
    width: 60%;
    margin-bottom: 50px;
  }
  .portfolio-button-wrapper {
    margin-top: 20px;
  }
  .btn.gy-primary {
    padding: .375rem .5rem;
    min-width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-wrapper {
    float: left;
    width: 20%;
  }
  .cards-wrapper {
    display: flex;
    justify-content: space-around;
    width: fit-content;
  }
  .cards-scroll-wrapper {
    float: left;
    width: 80%;
    margin-bottom: 50px;
  }
  .portfolio-button-wrapper {
    margin-top: 50px;
  }
  .btn.gy-primary {
    padding: .375rem .5rem;
    min-width: fit-content;
  }
  .left-arrow-button:hover, .right-arrow-button:hover {
    opacity: 0.35;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .portfolio-button-wrapper {
    margin-top: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
</style>
